import { notification } from "antd";

const formValidator = {}

const validateEmail = (email) => {
   return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

formValidator.validateAccountRegistration = (form, builder, setError) => {
   setError("")
   // Category
   if (!form.category) {
      // setError("Category is required")
      notification.error({message: "Category is required"})
      return 
   }

   builder.category = form.category
   // builder.category = 'individual'

   // Premium
   if (!form.premium_type) {
      setError("Premium Type is required")
      notification.error({message: "Premium Type is required"})
      return 
   }
   builder.premium_type = form.premium_type
   // builder.premium_type = 'Basic'

   // Association code
   if (!form.social_no && form.category && form.category === 'corporate') {
      notification.error({message: "Social Number is required"})
      return 
   }
   builder.social_no = form.social_no ?? ''
   // builder.social_no = 'AMANAPTITUDE'

   builder.enrolee_size = form.enrolee_size
   // builder.enrolee_size = 1

   // Name
   if (!form.name) {
      notification.error({message: "Full Name is required"})
      return 
   }
   builder.name = form.name

   // Email
   if (!form.email) {
      notification.error({message: "Email is required"})
      return 
   }

   if (!validateEmail(form.email)) {
      notification.error({message: "Invalid Email Input"})
      return 
   }
   builder.email = form.email

   // Email
   if (form.email !== form.confirm_email) {
      notification.error({message: "Email does not match"})
      return 
   }

   // builder.additional_heads = 0
   builder.additional_heads = form.additional_heads ?? 0

   if (!form.state) {
      notification.error({message: "State is requred"})
      return 
   }

   builder.state = form.state;

   if (!form.city) {
      notification.error({message: "city is required"})
      return 
   }

   builder.city = form.city;

   //validate the phone
   if (!form.phone) {
      notification.error({message: "phone number is required"})
      return 
   }
   if (!/^[0-9]+$/.test(form.phone)) {
      notification.error({message: "Phone number should be digits only"})
      return 
   }
   if (!/^0/.test(form.phone)) {
      notification.error({message: "Phone number must start with zero. e.g (070........)"})
      return 
   }
   if (form.phone.length !== 11) {
      notification.error({message: "Invalid phone number. Phone number expects 11 digits"})
      return 
   }
   builder.phone = form.phone

    // Email
    if (form.phone !== form.confirm_phone) {
      notification.error({message: "Phone number does not match"})
      return 
   }

   // validate password
   if (!form.password) {
      notification.error({message: "Password is required"})
      return 
   }

   if (form.confirm_password !== form.password) {
      notification.error({message: "Confirm Password Mismatch"})
      return 
   }

   builder.password = form.password

   // disclaimer
   builder.disclaimer = 1;

   console.log('Form: ', form)
   console.log('Builder: ', builder)

   return builder
}






// validate Principal form
formValidator.validatePrincipal = (form, builder, setError) => {
   setError("");

   builder.title = form.title

   // First name 
   if (!form.first_name) {
      return setError("First Name is required")
   }
   builder.first_name = form.first_name

   // Middle name
   // if (!form.middle_name) {
   //    return setError("Middle Name is required")
   // }
   builder.middle_name = form.middle_name

   // Last name
   if (!form.surname) {
      return setError("Surname is required")
   }
   builder.surname = form.surname

   // State
   builder.state = form.state

   // LGA
   builder.lga = form.lga

   // DOB
   builder.date_of_birth = form.date_of_birth

   // Gender

   builder.gender = form.gender

   // Religion
   builder.religion = form.religion

   // Marital Status
   builder.marital_status = form.marital_status

   // Job title
   builder.job_title = form.job_title

   // Primary Health Care Facility
   builder.primary_health_facility = form.primary_health_facility

   // Secondary Health Care Facility
   builder.secondary_health_facility = form.secondary_health_facility

   // Contact Address
   builder.contact_address = form.contact_address

   // Existing Medical Condition
   builder.existing_medical_condition = form.existing_medical_condition

   // Previous Medical Condition
   builder.previous_medical_condition = form.previous_medical_condition


   // return payload
   return builder
}

// validate Dependent form
formValidator.validateDependent = (form, builder, setError) => {
   setError("");

   builder.title = form.title

   // First name 
   if (!form.first_name) {
      setError("First Name is required")
      return false
   }
   builder.first_name = form.first_name

   // Middle name
   // if (!form.middle_name) {
   //    return setError("Middle Name is required")
   // }
   builder.middle_name = form.middle_name

   // Last name
   if (!form.surname) {
      return setError("Surname is required")
   }
   builder.surname = form.surname

   // if (!form.email) {
   //    setError("Email is required")
   //    return false
   // }
   builder.email = form.email

   builder.phone = form.phone

   // State
   if (!form.state) {
      setError("State is required")
      return false
   }
   builder.state = form.state

   // LGA
   if (!form.lga) {
      setError("LGA/City is required")
      return false
   }
   builder.lga = form.lga

   // DOB
   if (!form.birth_day) {
      setError("Birth Date is required")
      return false
   }
   builder.birth_day = form.birth_day

   // Gender
   if (!form.gender) {
      setError("Gender is required")
      return false
   }
   builder.gender = form.gender

   // Religion
   // if (!form.religion) {
   //    setError("Religion is required")
   //    return false
   // }
   builder.religion = form.religion

   // Marital Status
   if(form.role !== 'principal'){
      if (!form.relationship) {
         setError("Relationship is required")
         return false
      }
      builder.relationship = form.relationship
   }
   
   // Job title
   builder.job_title = form.job_title

   // Primary Health Care Facility
   if (!form.primary_health_facility) {
      setError("primary health facility is required")
      return false
   }
   builder.primary_health_facility = form.primary_health_facility

   // Secondary Health Care Facility
   // if (!form.relationship) {
   //    setError("secondary health facility is required")
   //    return false
   // }
   builder.secondary_health_facility = form.secondary_health_facility

   // Contact Address
   if (!form.contact_address) {
      setError("contact address is required")
      return false
   }
   builder.contact_address = form.contact_address

   // Existing Medical Condition
   if (!form.existing_medical_condition) {
      setError("existing medical condition is required")
      return false
   }
   builder.existing_medical_condition = form.existing_medical_condition

   // Previous Medical Condition
   if (!form.previous_medical_condition) {
      setError("previous medical condition is required")
      return false
   }
   builder.previous_medical_condition = form.previous_medical_condition


   // return payload
   return builder
}

// validate Dependent form
formValidator.validateDependentEdit = (form, builder, setError) => {
   setError("");

   // First name 
   // if (!form.first_name) {
   //    setError("First Name is required")
   //    return false
   // }
   // builder.first_name = form.first_name

   // Middle name
   // if (!form.middle_name) {
   //    return setError("Middle Name is required")
   // }
   // builder.middle_name = form.middle_name

   // // Last name
   // if (!form.surname) {
   //    return setError("Surname is required")
   // }
   // builder.surname = form.surname

   // if (!form.email) {
   //    setError("Email is required")
   //    return false
   // }
   builder.email = form.email

   // State
   if (!form.state) {
      setError("State is required")
      return false
   }
   builder.state = form.state

   // LGA
   if (!form.lga) {
      setError("LGA/City is required")
      return false
   }
   builder.lga = form.lga

   // DOB
   // if (!form.birth_day) {
   //    setError("Birth Date is required")
   //    return false
   // }
   // builder.birth_day = form.birth_day

   // Gender
   // if (!form.gender) {
   //    setError("Gender is required")
   //    return false
   // }
   // builder.gender = form.gender

   // Religion
   // if (!form.religion) {
   //    setError("Religion is required")
   //    return false
   // }
   builder.religion = form.religion

   // Marital Status
   // if (!form.relationship) {
   //    setError("Relationship is required")
   //    return false
   // }
   // builder.relationship = form.relationship

   // Job title
   builder.job_title = form.job_title

   // Primary Health Care Facility
   if (!form.primary_health_facility) {
      setError("primary health facility is required")
      return false
   }
   builder.primary_health_facility = form.primary_health_facility

   // Secondary Health Care Facility
   // if (!form.relationship) {
   //    setError("secondary health facility is required")
   //    return false
   // }
   builder.secondary_health_facility = form.secondary_health_facility

   // Contact Address
   if (!form.contact_address) {
      setError("contact address is required")
      return false
   }
   builder.contact_address = form.contact_address

   // Existing Medical Condition
   if (!form.existing_medical_condition) {
      setError("existing medical condition is required")
      return false
   }
   builder.existing_medical_condition = form.existing_medical_condition

   // Previous Medical Condition
   if (!form.previous_medical_condition) {
      setError("previous medical condition is required")
      return false
   }
   builder.previous_medical_condition = form.previous_medical_condition


   // return payload
   return builder
}

export default formValidator