import axios from "axios";
// import { isDev } from "../../pages/lib";

export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const uri = 'https://api.amanmedicare.org/v1.1/slim/test/'; //testing 
// export const uri = 'https://api.amanmedicare.org/v1.1/slim/' // staging
// export const uri = 'https://api.amanmedicare.com/v1.1/' // staging
export const uri2 = 'https://api.amanmedicare.org/v1.1/' // staging

const inDev = isDev();

const header =  {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Version': '1.5.1',
    'Application': 1,
    'Dev':  inDev? 1 : 0
}

const Axios = axios.create({baseURL: uri, headers: header });

export default Axios;
