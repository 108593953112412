import React, { useState } from 'react';
import { useNotifications } from '@mantine/notifications';

import helpers from '../../core/Helpers';
import lib from '../lib';

import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';


const UploadDocument = (props) => {
  const notify = useNotifications();
  const [loading, setLoading] = useState(false);

  const showModal = (e) => {
    e.preventDefault()
    document.querySelector('#doc').click();
  };

  const onChange = async (event) => {
    setLoading(true);
    helpers.alert({ notifications: notify, icon: 'success', color: 'gold', message: "Uploding Payment Document" });
    const file = event.target.files[0]
    const isLt2M = file['size'] / 1024 / 1024 < 6;
    const formData = new FormData();

    formData.append('document', file);
    formData.append('user', JSON.stringify({policy_no: props.data.policy_no, email: props.data.email}))

    if (!isLt2M) {
      console.log(isLt2M);
      helpers.alert({ notifications: notify, icon: 'success', color: 'gold', message: 'Document must be less than 6MB!' });
      return
    } 

    let reqData = await lib.sendPaymentEvidence(formData)
    if (reqData.status === 'error') {
      helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.msg })
    }
    if (reqData?.error === 1) {
      helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: "Image upload Failed" })
    }
    if (reqData?.error === 0) {
      helpers.alert({ notifications: notify, icon: 'success', color: 'green', message: reqData.message });
      localStorage.setItem('paymentStatus', JSON.stringify({policy_no: props.data.policy_no, isPaid: true}));
      setTimeout(()=> window.location.reload(), 500)
    }
    setLoading(false);
  };

  return (
    <>
      <a type="primary" onClick={showModal} href='/'>click here to upload your payment evidence 
      {(!loading)?
        <UploadOutlined />
      : 
        <LoadingOutlined />
      }
      .
      </a>
      <input type='file' style={{display: "none"}} id='doc' name='doc' onChange={onChange} />
    </>
  )
}
export default UploadDocument;
 
 