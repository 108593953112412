
import React from 'react';
import { BsSquareHalf } from "react-icons/bs";
import './pricing.scss';
import { PageHeaderComp } from '../../components/pageHeader/pageHeader';
import { PackageCards } from '../../components/actionSelect/actionSelect'
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/icon/logotype.png';

const Pricing = () => {
    const navigate = useNavigate();
    
    const allData = [
        // {
        //     name: "BASIC PLAN",
        //     title: 'Starting at N1500 per individual per month',
        //     state: { option: "BASIC", price: 18000, sixPrice: 18000  },
        //     benefits: [
        //         'Telemedicine',
        //         'Coverage limit of upto N240,000',
        //         'Up to two specialist consultations per year',
        //         'Basic lab and routine radiological investigations'
        //     ]
        // }, 
        {
            name: "BRONZE PLAN",
            title: 'Starting at N45,822 for individuals and N169,543 for a family of six',
            state: { option: "BRONZE", price: 45822, sixPrice: 169543 },
            benefits: [
                'Telemedicine',
                'Antenatal and Postnatal Care',
                'Covers specialist consultation',
                'Coverage limit of upto N700,000',
            ]
        }, {
            name: "SILVER PLAN",
            title: 'Starting at N71,487 for individuals and N264,503 for a family of six',
            state: { option: "SILVER", price: 71487, sixPrice: 264503 },
            benefits: [
                'Telemedicine',
                'Covers immunizations',
                'Antenatal and Postnatal Care',
                'Covers specialist consultation',
                'Surgery limit of upto N150,000',
                'Coverage limit of upto N1,400,000',
            ]
        }, {
            name: "GOLD PLAN",
            title: 'Starting at N134,808 for individuals and N410,000 for a family of six',
            state: { option: "GOLD", price: 134808, sixPrice: 410000  },
            benefits: [
                'Telemedicine',
                'Covers immunizations',
                'Antenatal and Postnatal Care',
                'Surgery limit of upto N500,000',
                'Covers specialist consultation',
                'Coverage limit of upto N3,600,000',
                'Private Wards (upto 20 days per annum)'
            ]
        }, {
            name: "PLATINUM PLAN",
            title: 'Starting at N213,015 for individuals and N630,000 for a family of six',
            state: { option: "PLATINUM", price: 213015, sixPrice: 630000 },
            benefits: [
                'Telemedicine',
                'Doctor home visits',
                'Covers immunizations',
                'Cancer care (upto N500,000)',
                'Antenatal and Postnatal Care',
                'Covers specialist consultation',
                'Surgery limit of upto N1,000,000',
                'Coverage limit of upto N5,000,000',
                'Private Wards (upto 30 days per annum)',
            ]
        }
    ]

    return (
        <div className='pricing' style={{}}>
            <div className='pricing-image' style={{display: 'flex', justifyContent: 'center'}}>
                <img src={logo} style={{width: 100, height: 50, margin: "10px auto"}} alt='img'/>
            </div>
            <div className='pricing-container'>
                <PageHeaderComp title={<span style={{display: 'flex', alignItems: 'center', justifyItems:'center', justifyContent: 'center'}}><p><BsSquareHalf />&nbsp;Log Me In</p></span>} style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer", marginLeft: "20px", marginTop: "10px", textAlign: "center" }} onClick={() => navigate('/login', { replace: true })} />
                <PackageCards data = {allData} />
            </div>
        </div>
    )
}

export default Pricing;